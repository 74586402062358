import { services } from "../config/services";
import { customFetch } from "../customFetch";
import { Sorter } from "../sorter";
import { CoreSensitivity } from "../types/response/endpoints";

export interface GroupConfigRequest {
  name: string;
  isAutoUpdateEnabled: boolean;
  isQuarantineEnabled: boolean;
  isStartupGuardEnabled: boolean;
  isCoreEnabled: boolean;
  isCanaryEnabled: boolean;
  isAppListCollectionEnabled: boolean;
  hashPurgingTimeoutHours: number;
  heartbeatIntervalSeconds: number;
  protectedDirectories: string[];
  allowedPaths: string[];
  canaryFiles: string[];
  coreSensitivity: CoreSensitivity;
}

export const groupConfigs = {
  getGroupConfig: (id: string) => customFetch(`${services.groupConfigs}/${id}`),
  getGroupConfigsByName: (companyId: string, name: string) =>
    customFetch(
      `${services.groupConfigs}?CompanyId=${companyId}&Name=${name}&PageSize=20`,
    ),
  getGroupConfigs: (
    page: number,
    pageSize: number,
    companyId: string,
    sorter?: Sorter,
  ) => {
    let url = `${services.groupConfigs}?CompanyId=${companyId}&PageNumber=${page}&PageSize=${pageSize}`;
    if (sorter) {
      url += `&OrderBy=${sorter.orderBy}&OrderDirection=${sorter.orderDirection}`;
    }
    return customFetch(url);
  },
  addGroupConfig: (params: GroupConfigRequest & { companyId: string }) =>
    customFetch(`${services.groupConfigs}`, {
      method: "POST",
      body: JSON.stringify(params),
    }),
  deleteGroupConfig: (id: string) =>
    customFetch(`${services.groupConfigs}/${id}`, {
      method: "DELETE",
    }),
  updateGroupConfig: (id: string, params: GroupConfigRequest) =>
    customFetch(`${services.groupConfigs}/${id}`, {
      method: "PUT",
      body: JSON.stringify(params),
    }),
};
