import { services } from "../config/services";
import { customFetch } from "../customFetch";
import { CoreSensitivity } from "../types/response/endpoints";

export interface CompanyConfigRequest {
  isAggerServiceEnabled: boolean;
  isConnectedToGlobalHashes: boolean;
  areEmailNotificationsEnabled: boolean;
  defaultGroupConfigId: string | null | undefined;
  defaultIsAutoUpdateEnabled: boolean;
  defaultIsQuarantineEnabled: boolean;
  defaultIsStartupGuardEnabled: boolean;
  defaultIsCoreEnabled: boolean;
  defaultIsCanaryEnabled: boolean;
  defaultIsAppListCollectionEnabled: boolean;
  defaultHashPurgingTimeoutHours: number;
  defaultHeartbeatIntervalSeconds: number;
  defaultAllowedPaths: string[];
  defaultProtectedDirectories: string[];
  defaultCanaryFiles: string[];
  defaultCoreSensitivity: CoreSensitivity;
}

export const company = {
  getCompany: (companyId: string) =>
    customFetch(`${services.companies}/${companyId}`),
  getCompanyConfig: (companyId: string) =>
    customFetch(`${services.companies}/${companyId}/config`),
  getCompanies: (page: number, pageSize: number) =>
    customFetch(
      `${services.companies}?PageNumber=${page}&PageSize=${pageSize}`,
    ),
  updateCompanyConfig: (companyId: string, config: CompanyConfigRequest) =>
    customFetch(`${services.companies}/${companyId}/config`, {
      method: "PUT",
      body: JSON.stringify(config),
    }),
};
