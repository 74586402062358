import { services } from "../config/services";
import { customFetch } from "../customFetch";
import { ApprovementStatus } from "../types/response/endpoints";
import { GroupConfigRequest } from "./groupConfigs";
import { Sorter } from "../sorter";

export interface EndpointsFilters {
  state: "All" | boolean;
  status: "All" | ApprovementStatus;
  location: string;
  operatingSystem: string;
  aggerVersion: string;
}

export interface EndpointsSyncFilters {
  location: string;
  operatingSystem: string;
  aggerVersion: string;
}

export type EndpointConfigRequest = Omit<GroupConfigRequest, "name"> & {
  groupConfigId?: string | null;
};

export const endpoints = {
  getEndpoints: (
    page: number,
    pageSize: number,
    companyId: string,
    filters: EndpointsFilters,
    sorter?: Sorter,
  ) => {
    let url = `${services.endpoints}?CompanyId=${companyId}&PageNumber=${page}&PageSize=${pageSize}`;
    if (filters.state !== "All") {
      url += `&Online=${filters.state}`;
    }
    if (filters.status !== "All") {
      url += `&ApprovementStatus=${filters.status}`;
    }
    if (filters.location !== "All") {
      url += `&PublicIpCountryCode=${filters.location}`;
    }
    if (filters.operatingSystem !== "All") {
      url += `&OperatingSystem=${filters.operatingSystem}`;
    }
    if (filters.aggerVersion !== "All") {
      url += `&AggerVersion=${filters.aggerVersion}`;
    }
    if (sorter) {
      url += `&OrderBy=${sorter.orderBy}&OrderDirection=${sorter.orderDirection}`;
    }
    return customFetch(url);
  },
  getEndpoint: (endpointId: string) =>
    customFetch(`${services.endpoints}/${endpointId}`),
  getEndpointConfig: (endpointId: string) =>
    customFetch(`${services.endpoints}/${endpointId}/config`),
  changeEndpointConfig: (endpointId: string, config: EndpointConfigRequest) =>
    customFetch(`${services.endpoints}/${endpointId}/config`, {
      method: "PUT",
      body: JSON.stringify(config),
    }),
  approveEndpoint: (id: string) =>
    customFetch(`${services.endpoints}/${id}/approve`, { method: "POST" }),
  denyEndpoint: (id: string) =>
    customFetch(`${services.endpoints}/${id}/deny`, { method: "POST" }),
  approveEndpoints: (ids: string[]) =>
    customFetch(`${services.endpoints}/approve`, {
      method: "POST",
      body: JSON.stringify({ ids }),
    }),
  denyEndpoints: (ids: string[]) =>
    customFetch(`${services.endpoints}/deny`, {
      method: "POST",
      body: JSON.stringify({ ids }),
    }),
  changeLicense: (endpointId: string, licenseId: string) =>
    customFetch(`${services.endpoints}/${endpointId}`, {
      method: "PUT",
      body: JSON.stringify({ licenseId }),
    }),
  getGroupConfigEndpoints: (groupConfigId: string, page: number) =>
    customFetch(
      `${services.endpoints}?GroupConfigId=${groupConfigId}&PageNumber=${page}`,
    ),
  getEndpointsLocations: (companyId: string, filters: EndpointsSyncFilters) => {
    let url = `${services.endpoints}/locations?CompanyId=${companyId}`;
    if (filters.aggerVersion !== "All") {
      url += `&AggerVersion=${filters.aggerVersion}`;
    }
    if (filters.operatingSystem !== "All") {
      url += `&OperatingSystem=${filters.operatingSystem}`;
    }
    return customFetch(url);
  },
  getEndpointsOperatingSystems: (
    companyId: string,
    filters: EndpointsSyncFilters,
  ) => {
    let url = `${services.endpoints}/operating-systems?CompanyId=${companyId}`;
    if (filters.aggerVersion !== "All") {
      url += `&AggerVersion=${filters.aggerVersion}`;
    }
    if (filters.location !== "All") {
      url += `&PublicIpCountryCode=${filters.location}`;
    }
    return customFetch(url);
  },
  getEndpointsAggerVersions: (
    companyId: string,
    filters: EndpointsSyncFilters,
  ) => {
    let url = `${services.endpoints}/versions?CompanyId=${companyId}`;
    if (filters.operatingSystem !== "All") {
      url += `&OperatingSystem=${filters.operatingSystem}`;
    }
    if (filters.location !== "All") {
      url += `&PublicIpCountryCode=${filters.location}`;
    }
    return customFetch(url);
  },
  getEndpointApplications: (
    endpointId: string,
    page: number,
    search?: string,
  ) => {
    let url = `${services.endpoints}/applications?EndpointId=${endpointId}&PageNumber=${page}&PageSize=20`;
    if (search) {
      url += `&Name=${search}`;
    }
    return customFetch(url);
  },
  getEndpointApplication: (applicationId: string) =>
    customFetch(`${services.endpoints}/applications/${applicationId}`),
  getMaintenanceKey: (endpointId: string) =>
    customFetch(`${services.endpoints}/${endpointId}/maintenance-key`, {
      method: "POST",
    }),
};
