import { services } from "../config/services";
import { filterDateFormat } from "../constants";
import { customFetch } from "../customFetch";
import { Sorter } from "../sorter";
import dayjs from "dayjs";

export interface EventsFilters {
  type: string;
  location: string;
  from: string;
  to: string;
}

export interface EventsSyncFilters {
  type: string;
  location: string;
  from: string;
  to: string;
}

export enum EventsEntities {
  endpoint = "endpoint",
  hash = "hash",
  groupConfig = "groupConfig",
}

function formatEventDateFilter(from: string, to: string) {
  const formattedFrom = dayjs(from, filterDateFormat)
    .set("h", 0)
    .set("m", 0)
    .set("s", 0)
    .set("ms", 0)
    .toISOString();
  const formattedTo = dayjs(to, filterDateFormat)
    .set("h", 23)
    .set("m", 59)
    .set("s", 59)
    .set("ms", 999)
    .toISOString();
  return [formattedFrom, formattedTo];
}

export const events = {
  getEvents: (
    page: number,
    pageSize: number,
    companyId: string,
    filters: EventsFilters,
    sorter?: Sorter,
  ) => {
    let url = `${services.events}?CompanyId=${companyId}&PageNumber=${page}&PageSize=${pageSize}`;
    if (filters.type !== "All") {
      url += `&Type=${filters.type}`;
    }
    if (filters.location !== "All") {
      url += `&IpCountryCode=${filters.location}`;
    }
    if (filters.from && filters.to) {
      const [from, to] = formatEventDateFilter(filters.from, filters.to);
      url += `&CreatedFrom=${from}&CreatedTo=${to}`;
    }
    if (sorter) {
      url += `&OrderBy=${sorter.orderBy}&OrderDirection=${sorter.orderDirection}`;
    }
    return customFetch(url);
  },
  getEventsByEntity: (
    page: number,
    id: string,
    entity: EventsEntities,
    filters: EventsFilters,
    sorter?: Sorter,
  ) => {
    let url = `${services.events}?PageNumber=${page}&PageSize=20`;
    switch (entity) {
      case EventsEntities.endpoint:
        url += `&EndpointId=${id}`;
        break;
      case EventsEntities.hash:
        url += `&HashId=${id}`;
        break;
      case EventsEntities.groupConfig:
        url += `&GroupConfigId=${id}`;
        break;
    }
    if (filters.type !== "All") {
      url += `&Type=${filters.type}`;
    }
    if (filters.location !== "All") {
      url += `&IpCountryCode=${filters.location}`;
    }
    if (filters.from && filters.to) {
      const [from, to] = formatEventDateFilter(filters.from, filters.to);
      url += `&CreatedFrom=${from}&CreatedTo=${to}`;
    }
    if (sorter) {
      url += `&OrderBy=${sorter.orderBy}&OrderDirection=${sorter.orderDirection}`;
    }
    return customFetch(url);
  },
  getEvent: (id: string) => customFetch(`${services.events}/${id}`),
  getEventsTypes: (companyId: string, filters: EventsSyncFilters) => {
    let url = `${services.events}/types?CompanyId=${companyId}`;
    if (filters.location !== "All") {
      url += `&IpCountryCode=${filters.location}`;
    }
    if (filters.from && filters.to) {
      const [from, to] = formatEventDateFilter(filters.from, filters.to);
      url += `&CreatedFrom=${from}&CreatedTo=${to}`;
    }
    return customFetch(url);
  },
  getEventsLocations: (companyId: string, filters: EventsSyncFilters) => {
    let url = `${services.events}/locations?CompanyId=${companyId}`;
    if (filters.type !== "All") {
      url += `&Type=${filters.type}`;
    }
    if (filters.from && filters.to) {
      const [from, to] = formatEventDateFilter(filters.from, filters.to);
      url += `&CreatedFrom=${from}&CreatedTo=${to}`;
    }
    return customFetch(url);
  },
  getEventsTypesByEntity: (
    id: string,
    entity: EventsEntities,
    filters: EventsSyncFilters,
  ) => {
    let url = `${services.events}/types?`;
    switch (entity) {
      case EventsEntities.endpoint:
        url += `EndpointId=${id}`;
        break;
      case EventsEntities.hash:
        url += `HashId=${id}`;
        break;
      case EventsEntities.groupConfig:
        url += `GroupConfigId=${id}`;
        break;
    }
    if (filters.location !== "All") {
      url += `&IpCountryCode=${filters.location}`;
    }
    if (filters.from && filters.to) {
      const [from, to] = formatEventDateFilter(filters.from, filters.to);
      url += `&CreatedFrom=${from}&CreatedTo=${to}`;
    }
    return customFetch(url);
  },
  getEventsLocationsByEntity: (
    id: string,
    entity: EventsEntities,
    filters: EventsSyncFilters,
  ) => {
    let url = `${services.events}/locations?`;
    switch (entity) {
      case EventsEntities.endpoint:
        url += `EndpointId=${id}`;
        break;
      case EventsEntities.hash:
        url += `HashId=${id}`;
        break;
      case EventsEntities.groupConfig:
        url += `GroupConfigId=${id}`;
        break;
    }
    if (filters.type !== "All") {
      url += `&Type=${filters.type}`;
    }
    if (filters.from && filters.to) {
      const [from, to] = formatEventDateFilter(filters.from, filters.to);
      url += `&CreatedFrom=${from}&CreatedTo=${to}`;
    }
    return customFetch(url);
  },
};
